input {
	border: 0;
	outline: 0;
}
input:focus {
	outline: none !important;
}

.react-datepicker__input-container input {
	border: 0;
	outline: 0;
	height: 30px;
	width: 100%;
	opacity: 0;
}

.react-datepicker {
	z-index: 999 !important;
}

.MuiCard-root {
	overflow: visible !important;
}

input {
	caret-color: "#FFF";
}

.react-datepicker-popper {
	z-index: 9999 !important;
}

.MuiCard-root {
	overflow: visible !important;
}

.calendar-popout {
	z-index: 999 !important;
}
